import React from "react";
import model from "../assets/images/model.png";
import js from "../assets/images/software_svg/js.svg";
import html from "../assets/images/software_svg/html.svg";
import css from "../assets/images/software_svg/css.svg";
import xd from "../assets/images/software_svg/xd.svg";
import react from "../assets/images/software_svg/react.svg";
import ai from "../assets/images/software_svg/ai.svg";
import vue from "../assets/images/software_svg/vue.svg";
import github from "../assets/images/github.svg";
import linkedIn from "../assets/images/linkedIn.svg";
import instagram from "../assets/images/instagram.svg";



export default function Banner() {
  return (
    <>
      <div className="banner">
        <div className="md:hidden md:col-span-2 flex items-center justify-center pt-[80px] pb-[80px]">
          <div className="relative">
          <img
              src={model}
              className="border-[2px] border-[#04AED9] rounded-[8px] object-cover bg-gradient-to-b from-[#032134] to-[#000000] w-[300px] h-[300px] md:w-[400px] md:h-[400px] lg:w-[500px] lg:h-[500px]"
              alt="Renoel"
            />
            <div className="absolute right-0 left-0 mx-auto w-[68%] bottom-[-50px]">
              <div className="bg-gradient-to-b from-[#032134] to-[#000000] flex items-center justify-center border-[2px] rounded-full border-[#04AED9] w-[205px] h-[80px] gap-5">
                <div className=" border-[2px] rounded-full p-2 border-[#04AED9]">
                  <a href="https://github.com/renoel" target="_blank" rel="noreferrer">
                    <img  src={github} alt="github" />
                  </a>
                </div>
                <div className="border-[2px] rounded-full p-2 border-[#04AED9]">
                  <a href="https://www.linkedin.com/in/renoel-emelina/" target="_blank" rel="noreferrer">
                    <img className="h-5" src={linkedIn} alt="github" />
                  </a>
                </div>
                <div className=" border-[2px] rounded-full p-2 border-[#04AED9]">
                  <a href="https://www.instagram.com/renna.jr/" target="_blank" rel="noreferrer">
                    <img className="h-5" src={instagram} alt="github" />
                  </a>
                </div>
              </div>
            </div>
          </div> 
        </div>
        <div className="flex justify-center">
          <div className="col-span-2 flex items-center justify-center lg:col-span-1 md:px-5 lg:pr-0 md:ml-[50ppx] lg:ml-[100px] xl:mx-auto w-[300px] sm:w-[400px] md:w-[auto]">
            <div className="flex flex-col justify-center leading-tight">
              <p className="text-[19px] sm:text-[30px] md:text-[50px] text-[#04AED9] font-medium">
                Welcome to my portfolio
              </p>
              <p className="text-[19px] sm:text-[30px] md:text-[50px] xl:text-[75px] font-bold">
                I'm Renoël Emelina
              </p>
              <p className="text-[19px] sm:text-[25px] md:text-[35px] text-[#04AED9] font-medium">
                Software Developer & Designer
              </p>
              <div className="flex flex-row pt-2 pb-2 gap-[10px] ">
                <div className="w-[20px] md:w-[50px] h-[1px] md:h-1 bg-[#04AED9]" />
                <div className="w-[220px] md:w-[400px] lg:w-[400px] xl:w-full h-[1px] md:h-1 bg-[#04AED9]" />
              </div>
              <p className="max-w-2xl sm:text-[20px] text-[15px] md:text-[24px] pt-4">
              I am a software developer and designer with about 4 years of experience. 
              I am extremely passionate about developing websites and applications, 
              and I also enjoy designing them.
              </p>
            </div>
          </div>
        </div>
        
        <div className="hidden lg:flex lg:items-end ">
          <div className="ml-auto">
            <div className="blue-line" />
            <img
              src={model}
              className="xl:w-[500px] xl:h-[500px] lg:min-w-[400px] lg:h-[400px]"
              alt="Renoel"
            />
            <div className="blue-line mt-[-4px] absolute right-0" />
          </div>
        </div>

        <div className="floating-banner">
          <div className="flex justify-center items-center h-full gap-10">
            <div className=" marquee border-x-[#04AED9] border-x-[2px]">
              <div className="flex flex-row gap-[100px] lg:gap-[150px] track">
                <img className="w-[50px] xl:w-[80px]" src={js} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={html} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={css} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={react} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={vue} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={xd} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={ai} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={js} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={html} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={css} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={react} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={vue} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={xd} alt="" />
                <img className="w-[50px] xl:w-[80px]" src={ai} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

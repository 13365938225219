import React from "react";
import "./Popup.css";
import img from "../assets/images/experiences_png/royal.png";

function PopupRoyal({ trigger, setTrigger }) {
  return trigger ? (
    <div className="popup">
      <div
        className={`popup-content ${
          setTrigger ? "animation-open" : ""
        }`}
      >
        <div className="h-[470px] grid md:grid-cols-2 overscroll-none overflow-auto">
          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px]">
            <img
              className="rounded-full w-[80px] flex md:block md:w-[130px] md:h-[130px]"
              src={img}
              alt="433"
            />
            <div className="flex flex-col">
              <h1 className="mt-4 font-bold text-[20px] sm:text-[25px] md:text-[30px]">
                Royalhaskoning - Fullstack Developer
              </h1>
              <p className="text-[#04AED9] font-bold">6 Months internship 2022 - 2022</p>
            </div>
            <div className="mt-2">
              Royalhaskoning is one of the biggest engineering companies in the Netherlands.
              Within royalhaskoning there are a few branches that focus on different things. 
              One of them is the branch rail. 
              This branch focuses on thinking of the most efficient way of building the 
              railinfratructure and time efficientcy for the trains and more.
              Simulations are made in order to find the best solution for the railinfrastructure.
            </div>
            
          </div>

          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px] md:pr-[50px]">
            <div className="pt-10 md:pt-0">
              <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold gap-2 flex ">
                Project <p className="text-[#04AED9]">Raimont</p>
              </h1>
            </div>
            <div className="mt-2 md:hidden">
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <p className=""> 
                The process of running a simulation was very time consuming because all the data needed was manually put in the simulation application.
                This project was designed to make the process of running a simulation more efficient by automating the process of putting in the data.
              </p>
              <p className="">
                Project raimont is an application that is used to convert an file where all the data is stored to a file that can be used in the simulation application.
              </p>
            </div>
          </div>

        </div>
       
        <div className="button-return">
          <button
            className={`close-btn w-[100px] md:w-[200px] border rounded-full md:rounded-[16px] ${
              trigger ? "" : "animation-close"
            }`}
            onClick={() => setTrigger(false)}
          >
            <p className="font-bold text-[16px]">Return</p> 
          </button>
        </div>
        
      </div>

    </div>
  ) : (
    ""
  );
}

export default PopupRoyal;

import React from "react";
import "./Popup.css";
import img from "../assets/images/experiences_png/433.png";

function PopupFootball({ trigger, setTrigger, children }) {
  return trigger ? (
    <div className="popup">
      <div
        className={`popup-content ${
          setTrigger ? "animation-open" : ""
        }`}
      >
        <div className="h-[470px] grid md:grid-cols-2 overscroll-none overflow-auto">
          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px]">
            <img
              className="rounded-full w-[80px] flex md:block md:w-[130px] md:h-[130px]"
              src={img}
              alt="433"
            />
            <div className="flex flex-col">
              <h1 className="mt-4 font-bold text-[20px] sm:text-[25px] md:text-[30px]">
                433 Football - Fullstack Developer
              </h1>
              <p className="text-[#04AED9] font-bold">6 Months internship 2022 - 2023</p>
            </div>
            <div className="mt-2">
              433 football is a company that has one of the biggest social media football communities in the world. 
              In order to make great videos 433 hire content creators to make videos for them. 433 made asumptions on how much a content creator is worth.
              In order to calculate the value of a content creator they came up with the project creators dashboard.
            </div>
          </div>

          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px] md:pr-[50px]">
            <div className="pt-10 md:pt-0">
              <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold gap-2 flex ">
                Project <p className="text-[#04AED9]">Creators Dashboard</p>
              </h1>
            </div>
            <div className="mt-2 md:hidden">
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <p className="">
                This project consist of 3 parts. A form where a content creator will fill in their personal en social media information. the second part is a webscraper that will scrape the data from the social media platforms. The last part is a dashboard where the data will be displayed.
              </p>
              <p className="">
                once the information is displayed a calculation will be made to determine the value of the content creator.
              </p>
            </div>
          </div>

        </div>
       
        <div className="button-return">
          <button
            className={`close-btn w-[100px] md:w-[200px] border rounded-full md:rounded-[16px] ${
              trigger ? "" : "animation-close"
            }`}
            onClick={() => setTrigger(false)}
          >
            <p className="font-bold text-[16px]">Return</p> 
          </button>
        </div>
        
      </div>

    </div>
  ) : (
    ""
  );
}

export default PopupFootball;

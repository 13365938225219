import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import App from "./App";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
// import SideNav from "./components/SideNav";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <div id="home"/>
      <Navbar />
      <Banner />
      <App />
      <Projects />
      <Experience />
      <Contact />
      <Footer />
    </>
  </React.StrictMode>
);

import React, { useState } from "react";
import logo from "../assets/images/logo.svg";
import linkedIn from "../assets/images/linkedIn.svg";
import github from "../assets/images/github.svg";
import SideNav from "./SideNav";

export default function Navbar() {
  const [colorChange, setColorChange] = useState(false);
  const [burger, setBurger] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 890) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  const changeBurgerColor = () => {
    if (window.scrollY >= 190) {
      setBurger(true);
    } else {
      setBurger(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  window.addEventListener("scroll", changeBurgerColor);

  return (
    <div className={`fixed md:w-full md:top-0 z-10 ${toggleNav ? "bg-black" : ""}`}>
      <div className="md:hidden">
        <div
          className={`w-[50px] h-[50px] flex justify-center items-center ${
            burger ? "" : ""
          }`}
        >
          <div
            id="nav-icon3"
            onClick={() => setToggleNav(!toggleNav)}
            className={`${toggleNav ? "open" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <SideNav open={toggleNav} close={setToggleNav}/>
      <div className={` ${colorChange ? "nav-scroll" : "nav-normal"}`}>
        <div className="navbar-container">
          <div className="p-4 flex items center">
            <div>
              <a href="#home">
                <img className="w-[70px]" src={logo} alt="Logo" />
              </a>
            </div>
            <div className="flex items-center ml-auto">
              <div>
                <nav className="leadin-6">
                  <ul className="flex space-x-8">
                    <a href="#home">
                      <li className="nav-links">Home</li>
                    </a>
                    <a href="#about">
                      <li className="nav-links">About me</li>
                    </a>
                    <a href="#projects">
                      <li className="nav-links">Projects</li>
                    </a>
                    <a href="#experience">
                      <li className="nav-links">Experiences</li>
                    </a>
                    <a href="#contact">
                      <li className="nav-links">Contact</li>
                    </a>
                  </ul>
                </nav>
              </div>
              <span className="nav-text">
                <div className="">
                  <div className="nav-icon">
                    <a
                      href="https://www.linkedin.com/in/renoel-emelina/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedIn} alt="linkedIn" />
                    </a>
                  </div>
                  <div className="nav-icon">
                    <a
                      href="https://github.com/renoel"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={github} className="w-[60%]" alt="github" />
                    </a>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

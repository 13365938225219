import React from "react";
import "./Popup.css";
import img from "../assets/images/experiences_png/gmu.png";

function PopupGmu({ trigger, setTrigger }) {
  return trigger ? (
    <div className="popup">
      <div
        className={`popup-content ${
          setTrigger ? "animation-open" : ""
        }`}
      >
        <div className="h-[470px] grid md:grid-cols-2 overscroll-none overflow-auto">
          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px]">
            <img
              className="rounded-full w-[80px] flex md:block md:w-[130px] md:h-[130px]"
              src={img}
              alt="433"
            />
            <div className="flex flex-col">
              <h1 className="mt-4 font-bold text-[20px] sm:text-[25px] md:text-[30px]">
                GMU online. - Fullstack Developer
              </h1>
              <p className="text-[#04AED9] font-bold">6 Months internship 2021 - 2021</p>
            </div>
            <div className="mt-2">
              GMU online is an marketing agency that helps businesses to grow. They are shifting into a software developing company.
              they have many clients that need a website or webapplication.  
            </div>
            
          </div>

          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px] md:pr-[50px]">
            <div className="pt-10 md:pt-0">
              <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold gap-2 flex ">
                Project <p className="text-[#04AED9]">Location Finder</p>
              </h1>
            </div>
            <div className="mt-2 md:hidden">
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <p className="">
                One of the clients of GMU online is a company that build charging stations for electric cars.
                They have a lot of charging stations in the Netherlands and they want to make a webapplication where people can find an optimal place to build a charging station.
              </p>
              <p className="">
          
              </p>
            </div>
          </div>

        </div>
       
        <div className="button-return">
          <button
            className={`close-btn w-[100px] md:w-[200px] border rounded-full md:rounded-[16px] ${
              trigger ? "" : "animation-close"
            }`}
            onClick={() => setTrigger(false)}
          >
            <p className="font-bold text-[16px]">Return</p> 
          </button>
        </div>
        
      </div>

    </div>
  ) : (
    ""
  );
}

export default PopupGmu;

import React from "react";
import logo from "../assets/images/logo.svg";

function SideNav({ open, close}) {

  const isOpen = open;
  const isClose = close;

  return (


    <div className={`h-screen bg-black w-screen ${isOpen ? "" : "hidden"}`}>
      <div className="">
        <ul className="h-screen">
          <li className="flex flex-col gap-7 text-[20px] items-center ">
            <img className="w-[70px] right-0" src={logo} alt="Logo" />
            <hr className=""/>
            <a href="#home" onClick={() => isClose(false)} >Home</a>
            <a href="#about" onClick={() => isClose(false)}>About</a>
            <a href="#projects" onClick={() => isClose(false)}>Projects</a>
            <a href="#experience" onClick={() => isClose(false)}>Experience</a>
            <a href="#contact" onClick={() => isClose(false)}>Contact</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideNav;

import React, { useState } from "react";
import PopupFootball from "./PopupFootball";
import PopupRoyal from "./PopupRoyal";
import PopupGmu from "./PopupGmu";
import PopupSowiso from "./PopupSowiso";
import PopupXinto from "./PopupXinto";
import football from "../assets/images/experiences_png/433.png";
import royal from "../assets/images/experiences_png/royal.png";
import sowiso from "../assets/images/experiences_png/sowiso.png";
import gmu from "../assets/images/experiences_png/gmu.png";
import xinto from "../assets/images/experiences_png/xinto.png";

export default function Experience() {
  const [buttonFootball, setButtonFootball] = useState(false);
  const [buttonRoyal, setButtonRoyal] = useState(false);
  const [buttonSowiso, setButtonSowiso] = useState(false);
  const [buttonGmu, setButtonGmu] = useState(false);
  const [buttonXinto, setButtonXinto] = useState(false);

  return (
    <div id="experience" className="md:pt-[250px] px-14">

      <div className="flex justify-center md:hidden mt-20">
        <div className="pb-10 w-[300px] sm:w-[400px]">
          <div className="w-[100px] sm:w-[150px]">
            <div className="w-[40px] h-[1px] bg-[#04AED9] ml-auto" />
            <p className="text-[19px] sm:text-[25px] text-white font-medium">Experiences</p>
            <div className="w-[40px] h-[1px] bg-[#04AED9]" />
          </div> 
          <div className="pt-10 grid grid-cols-2 justify-items-center gap-10">
              <PopupFootball
                trigger={buttonFootball}
                setTrigger={setButtonFootball}
              />

              <PopupRoyal trigger={buttonRoyal} setTrigger={setButtonRoyal} />
              <PopupGmu trigger={buttonGmu} setTrigger={setButtonGmu} />
              <PopupSowiso
                trigger={buttonSowiso}
                setTrigger={setButtonSowiso}
              />
              <PopupXinto trigger={buttonXinto} setTrigger={setButtonXinto} />

          <div className="flex flex-col justify-center items-center">
            <div className="rounded-full w-20 h-20 bg-black  flex items-center overflow-hidden">
              <img
                src={xinto}
                alt="Xinto"
                className="w-[80px] h-[80px]"
                onClick={() => setButtonXinto(true)}
              />
            </div>
            <div className="pt-2">
              <p className="font-bold text-[12px]">Jan 2022 - Now</p> 
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="rounded-full w-20 h-20 bg-black  flex justify-center items-center overflow-hidden">
              <img
                src={football}
                alt="433 football"
                className="w-[80px] h-[80px]"
                onClick={() => setButtonFootball(true)}
              />
            </div>
            <div className="pt-2">
              <p className="font-bold text-[12px]">Sept 2022 - Feb 2023</p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="rounded-full w-20 h-20 bg-black  flex justify-center items-center overflow-hidden">
              <img
                src={royal}
                alt="Royal Haskoning"
                className="w-[80px] h-[80px]"
                onClick={() => setButtonRoyal(true)}
              />
            </div>
            <div className="pt-2">
              <p className="font-bold text-[12px]">Feb 2022 - Jun 2022</p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="rounded-full w-20 h-20 bg-black  flex justify-center items-center overflow-hidden">
              <img
                src={gmu}
                alt="GMU"
                className="w-[80px] h-[80px]"
                onClick={() => setButtonGmu(true)}
              />
            </div>
            <div className="pt-2">
              <p className="font-bold text-[12px]">Feb 2021 - Jun 2021</p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="rounded-full w-20 h-20 bg-black flex justify-center items-center overflow-hidden">
              <img
                src={sowiso}
                alt="Sowiso"
                className="w-[80px] h-[80px]"
                onClick={() => setButtonSowiso(true)}
              />
            </div>
            <div className="pt-2">
              <p className="font-bold text-[12px]">Sept 2021 - Feb 2022</p>
            </div>
          </div>

          </div>
        </div>
      </div>

      <div className="hidden md:flex">
        <div className="experience overflow-hidden">
          <div className="relative">
            <div className="flex flex-col gap-10 ">
              <div className="max-w-[230px] m-16 ">
                <div className="max-w-[100px] h-[4px] bg-[#04AED9]" />
                <p className="text-[35px] text-white font-medium">Experiences</p>
                <div className="max-w-[150px] h-[4px] bg-[#04AED9] ml-auto" />
              </div>
              <div className="flex flex-row items-center pb-[80px] overflow-auto scroll">
                <PopupFootball
                  trigger={buttonFootball}
                  setTrigger={setButtonFootball}
                />

                <PopupRoyal trigger={buttonRoyal} setTrigger={setButtonRoyal} />
                <PopupGmu trigger={buttonGmu} setTrigger={setButtonGmu} />
                <PopupSowiso
                  trigger={buttonSowiso}
                  setTrigger={setButtonSowiso}
                />
                <PopupXinto trigger={buttonXinto} setTrigger={setButtonXinto} />

                <div className="flex flex-col mx-16 items-center  min-w-[170px]">
                  <div className="flex justify-center font-bold">Xinto</div>
                  <div>
                    <img
                      className="rounded-full w-24 h-24 flex items-center justify-center my-10 hover:scale-[1.10] duration-300 cursor-pointer select-none"
                      src={xinto}
                      alt="Xinto"
                      onClick={() => setButtonXinto(true)}
                    />
                  </div>
                  <div className="flex justify-center font-bold">
                    2022 Jan - Present
                  </div>
                </div>

                <div className="min-w-[100px] h-[2px] bg-[#04AED9] rounded" />

                <div className="flex flex-col mx-16 items-center min-w-[170px]">
                  <div className="flex font-bold">433</div>
                  <div className="">
                    <img
                      className="rounded-full w-24 h-24 flex items-center justify-center my-10 hover:scale-[1.10] duration-300 cursor-pointer select-none"
                      src={football}
                      alt="433"
                      onClick={() => setButtonFootball(true)}
                    />
                  </div>
                  <div className="font-bold ml-auto w-[250px] mr-auto">2022 Sept - 2023 Feb</div>
                </div>

                <div className="min-w-[100px] h-[2px] bg-[#04AED9] rounded" />

                <div className="flex flex-col mx-16 items-center min-w-[170px]">
                  <div className="flex justify-center font-bold">
                    Royal Haskoning
                  </div>
                  <div className="rounded-full w-[90px] h-[90px] flex items-center justify-center my-10 hover:scale-[1.10] duration-300 cursor-pointer select-none overflow-hidden">
                    <img
                      src={royal}
                      alt="Royal Haskoning"
                      className="w-[90px] h-[90px]"
                      onClick={() => setButtonRoyal(true)}
                    />
                  </div>
                  <div className="flex justify-center font-bold">
                    2022 Jan - 2022 July
                  </div>
                </div>
                <div className="min-w-[100px] h-[2px] bg-[#04AED9] rounded" />

                <div className="flex flex-col mx-16 items-center  min-w-[170px]">
                  <div className="flex justify-center font-bold">GMU online.</div>
                  <div>
                    <img
                      className="rounded-full w-24 h-24 flex items-center justify-center my-10 hover:scale-[1.10] duration-300 cursor-pointer select-none"
                      src={gmu}
                      alt="GMU online"
                      onClick={() => setButtonGmu(true)}
                    />
                  </div>
                  <div className="flex justify-center font-bold">
                    2021 Sept - 2022 Feb
                  </div>
                </div>

                <div className="min-w-[100px] h-[2px] bg-[#04AED9] rounded" />

                <div className="flex flex-col mx-16 items-center  min-w-[170px]">
                  <div className="flex justify-center font-bold">Sowiso</div>
                  <div>
                    <img
                      className="rounded-full w-24 h-24 flex items-center justify-center my-10 hover:scale-[1.10] duration-300 cursor-pointer select-none"
                      src={sowiso}
                      alt="Sowiso"
                      onClick={() => setButtonSowiso(true)}
                    />
                  </div>
                  <div className="flex justify-center font-bold">
                    2021 Feb - 2022 July
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  );
}

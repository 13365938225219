import js from "../src/assets/images/software_svg/js.svg";
import html from "../src/assets/images/software_svg/html.svg";
import css from "../src/assets/images/software_svg/css.svg";
import xd from "../src/assets/images/software_svg/xd.svg";
import react from "../src/assets/images/software_svg/react.svg";
import ai from "../src/assets/images/software_svg/ai.svg";
import vue from "../src/assets/images/software_svg/vue.svg";

function App() {
  return (
    <>
      <div className="about" id="about">
        <div className="md:hidden">
          <div className="flex justify-center">
            <div className="pb-7 w-[300px] sm:w-[400px]">
              <p className=" text-[19px] sm:text-[25px] text-white font-medium">
                Skills
              </p>
              <div className="w-[40px] h-[1px] bg-[#04AED9]" />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="grid grid-cols-2 gap-y-20 gap-x-20 w-[300px] sm:w-[400px]">
              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={react} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>

              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={vue} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>

              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={js} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>

              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={html} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>

              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={css} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>

              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={xd} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>

              <div className="w-[100px] h-[100px] flex justify-center rounded-[8px] border border-[#04AED9] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                <div className="flex">
                  <img className="w-[60px]" src={ai} alt="adobe xd" />
                </div>
                <div className="absolute left-0 right-0 w-[80px] h-[40px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-[#04AED9]">
                  <div className="absolute left-0 right-0 bottom-2 mx-[10px]">
                    3 years
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center md:hidden">
          <div className="pt-32 w-[300px] sm:w-[400px]">
            <div className=" w-[300px] sm:w-[400px]">
              <div className="w-[100px] sm:w-[150px]">
                <div className="w-[40px] h-[1px] bg-[#04AED9] ml-auto" />
                <p className="text-[19px] sm:text-[25px] text-white font-medium">
                  About me
                </p>
                <div className="w-[40px] h-[1px] bg-[#04AED9]" />
              </div>
            </div>
            <div className="pt-4">
              <p>
                I have a great understanding in the programming languages viewed in
                the skills list and I am always looking for ways to improve my
                skills and stay up-to-date with the latest industry trends. I am
                a team player and thrive in collaborative environments, but I am
                also able to work independently and take ownership of my
                projects. In my spare time, I enjoy playing the guitar, working
                out and am always looking for new and exciting challenges to
                tackle.
              </p>
            </div>
          </div>
        </div>

        <div className="hidden md:flex md:flex-col md:w-[40%] lg:pl-14">
          <div>
            <div className="w-[200px]">
              <div className="max-w-[80px] h-[4px] bg-[#04AED9] ml-auto" />
              <p className="text-[35px] text-white font-medium">About me</p>
              <div className="max-w-[100px] h-[4px] bg-[#04AED9]" />
            </div>
            <div className="pt-4">
              <p className="sm:text-[20px] text-[15px]">
              I have a great understanding in the programming languages listed in
                the skills section and I am always looking for ways to improve my
                skills. I am
                a team player and thrive in collaborative environments, but I am
                also able to work independently and take ownership of my
                projects. In my spare time, I enjoy playing the guitar, working
                out and am always looking for new and exciting challenges to
                tackle.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden md:flex md:flex-col md:ml-auto md:w-[40%] lg:pr-14">
          <div className="">
            <div className="w-[200px]">
              <p className="w-[80px] text-[35px] text-white font-medium">
                Skills
              </p>
              <div className="max-w-[80px] h-[4px] bg-[#04AED9]" />
            </div>
            <div className="flex flex-col gap-4 pt-10 w-[300px] md:w-auto">
              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>PHP</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[86%] ">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>Javascript</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[93%]">
                    {" "}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>HTML</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[85%]">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>CSS</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[90%]">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>React</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[90%]">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>MySQL</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[87%]">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>Vue</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[89%]">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>Adobe XD</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[95%]">
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <div className="skill-text">
                  <p>Illustrator</p>
                </div>
                <div className="skill-bar flex items-center">
                  <div className="bg-[#04AED9] h-[15px] rounded-[50px] ml-[2px] w-[89%]">
                    {" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;

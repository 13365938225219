import React from "react";
import linkedIn from "../assets/images/linkedIn.svg";
import instagram from "../assets/images/instagram.svg";
import github from "../assets/images/github.svg";

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <div className="flex flex-col">
        <div className="mx-20">
          <div className="max-w-[50px] md:max-w-[80px] h-[1px] md:h-[4px] bg-[#04AED9] ml-auto" />
          <div className="text-[25px] sm:text-[25px] md:text-[35px] sm:gap-3 text-white font-bold sm:flex grid justify-center">
            <p className="">
              Preparation 
            </p>
            <p className="text-[#04AED9]">
              Meets 
            </p>  
            <p>
              Opportunity
            </p>
          </div>
          <div className="max-w-[50px] md:max-w-[170px] h-[1px] md:h-[4px] bg-[#04AED9]" />
        </div>
        <div>
          <span className="flex justify-center mt-20 gap-10">
            <div className="contact-icon">
              <a href="https://www.linkedin.com/in/renoel-emelina/" target="_blank"  rel="noreferrer">
                <img src={linkedIn} alt="linkedIn" />
              </a>
            </div>
            <div className="contact-icon">
              <a href="https://github.com/renoel" target="_blank"  rel="noreferrer">
                <img src={github} alt="github" />
              </a>
            </div>
            <div className="contact-icon">
              <a href="https://www.instagram.com/renna.jr/" target="_blank"  rel="noreferrer">
                <img src={instagram} alt="github" />
              </a>
            </div>
          </span>
          <div className="font-bold text-[#04AED9] mt-20 flex justify-center text-[25px] sm:text-[25px] md:text-[35px]">
            See you soon!
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./Popup.css";
import img from "../assets/images/experiences_png/sowiso.png";



function PopupSowiso({ trigger, setTrigger }) {
  return trigger ? (
    <div className="popup">
       <div
        className={`popup-content ${
          setTrigger ? "animation-open" : ""
        }`}
      >
        <div className="h-[470px] grid md:grid-cols-2 overscroll-none overflow-auto">
          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px]">
            <img
              className="rounded-full w-[80px] flex md:block md:w-[130px] md:h-[130px]"
              src={img}
              alt="433"
            />
            <div className="flex flex-col">
              <h1 className="mt-4 font-bold text-[20px] sm:text-[25px] md:text-[30px]">
                433 Football - Fullstack Developer
              </h1>
              <p className="text-[#04AED9] font-bold">6 Months internship 2022 - 2023</p>
            </div>
            <div className="mt-2">
              Sowiso is an online platform where math teachers can create tests and exercises for their students. 
              The platform is used worldwide and is commonly used by universities.
            </div>
            
          </div>

          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px] md:pr-[50px]">
            <div className="pt-10 md:pt-0">
              <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold gap-2 flex ">
                Project <p className="text-[#04AED9]">Gamification</p>
              </h1>
            </div>
            <div className="mt-2 md:hidden">
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <p className="">
                Gamification will make it more fun for students to use the platform and encourage them to use it more often.
                Gamification will be implemented by adding a leaderboard and a level system. Also the students can gain experiences by completing exercises.
                There is also a possibility to receise a badge and achievement medals when completing a certain amount of exercises.

              </p>
              <p className="">
              </p>
            </div>
          </div>

        </div>
       
        <div className="button-return">
          <button
            className={`close-btn w-[100px] md:w-[200px] border rounded-full md:rounded-[16px] ${
              trigger ? "" : "animation-close"
            }`}
            onClick={() => setTrigger(false)}
          >
            <p className="font-bold text-[16px]">Return</p> 
          </button>
        </div>
        
      </div>

    </div>
  ) : (
    ""
  );
}

export default PopupSowiso;

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <div className="xl:w-[350px]">
      <div className="proj-imgbx w-[250px] h-[200px] xl:w-[350px] xl:h-[280px] rounded-[8px]">
        <img className="w-[250px] h-[200px] xl:w-[350px] xl:h-[280px]" src={imgUrl} alt="img1" />
        <div className="proj-txtx">
          <h4>{title}</h4>
        </div>
      </div>
      <div className="project-card-content">
        <span>{description}</span>
      </div>
    </div>
  );
};

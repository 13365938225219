import React from "react";
import "./Popup.css";
import img from "../assets/images/experiences_png/xinto.png";

function PopupXinto({ trigger, setTrigger }) {
  return trigger ? (
    <div className="popup">
       <div
        className={`popup-content ${
          setTrigger ? "animation-open" : ""
        }`}
      >
        <div className="h-[470px] grid md:grid-cols-2 overscroll-none overflow-auto">
          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px]">
            <img
              className="rounded-full w-[80px] flex md:block md:w-[130px] md:h-[130px]"
              src={img}
              alt="Xinto"
            />
            <div className="flex flex-col">
              <h1 className="mt-4 font-bold text-[20px] sm:text-[25px] md:text-[30px]">
                Xinto - CEO
              </h1>
              <p className="text-[#04AED9] font-bold">Company owner 2022 - Present</p>
            </div>
            <div className="mt-2">
              Xinto is my own company. I started this company begin 2021. Xinto focuses on building website an webaplication for small businesses and starters.
            </div>
            
          </div>

          <div className="md:flex md:flex-col md:gap-5 md:pl-[50px] md:pr-[50px]">
            <div className="pt-10 md:pt-0">
              <h1 className="text-[20px] sm:text-[25px] md:text-[30px] font-bold gap-2 flex ">
                Company <p className="text-[#04AED9]">Owner</p>
              </h1>
            </div>
            <div className="mt-2 md:hidden">
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <p className="">
                I always had a love for entrepreneurship and technologie. I decided last year to combine both of them and start my own company. I started with a small project for a friend and it grew from there. 
                Once i have my finished my school I will focus on building the company into something great.
              </p>
              <p className="">
                This is my first company and im very excited to see where it will take me. I learned already so much and i am very happy with the progress I made.
              </p>
            </div>
          </div>

        </div>
       
        <div className="button-return">
          <button
            className={`close-btn w-[100px] md:w-[200px] border rounded-full md:rounded-[16px] ${
              trigger ? "" : "animation-close"
            }`}
            onClick={() => setTrigger(false)}
          >
            <p className="font-bold text-[16px]">Return</p> 
          </button>
        </div>
        
      </div>

    </div>
  ) : (
    ""
  );
}

export default PopupXinto;

import React, { useState } from "react";
import { ProjectCard } from "./ProjectCard";
import gamification from "../assets/images/projects/gamification.png";
import locationFinder from "../assets/images/projects/locationfinder.png";
import raimont from "../assets/images/projects/raimont.png";
import foodtruck from "../assets/images/projects/foodtruck.png";
import ecommerce from "../assets/images/projects/ecommerce.png";

import styles from "./Cards.module.css";

export default function Projects() {
  const [activeTab, setActiveTab] = useState("first");

  const firstProjects = [
    {
      title: "Gamification",
      description: "The goal of this project is to motivate students to complete more assignments as this is built on a online math platform. This project is built in php and javascript.",
      imgUrl: gamification,
    },
    {
      title: "Ecommerece",
      description: "This is a hobby project where I build an eccomerce website. It is fully functional and has a payment system. This project is built in Javascript and React.js",
      imgUrl: ecommerce,
    },
    {
      title: "Raimont",
      description: "raimont is an application that converts a file that contains data given from a client into a file that can be uploaded to a simulation program in order to run a simulation. This project is built in C#.",
      imgUrl: raimont,
    },
  ];

  const secondProjects = [
    {
      title: "Foodtruck",
      description: "A client that has a foodtruck wanted to have a website where they can show their menu and where they are located. There is also an option where the customars can place an order. This project is built in Javascript and Vue.js",
      imgUrl: foodtruck,
    },
    {
      title: "Location Finder",
      description: "Rechargable cars are becoming more popular and the goal of this project is to find the best locations to build a new charging station. This project is built in php and javascript.",
      imgUrl: locationFinder,
    },
  ];

  // const thirdProjects = [
  // ];

  return (
    <div id="projects">
      <div className="projectsphone bg-black bg-opacity-10 mt-20 rounded md:hidden">
        <div className="flex flex-col">
          <div className="flex justify-center mt-10 pb-5 font-bold text-[20px] sm:text-[25px]">
            Projects
          </div>
          <div className="relative  w-full">
            <div className=" relative h-[200px] flex gap-10 snap-x snap-mandatory overflow-x-auto no-scrollbar">

              <div className="snap-center shrink-0">
                <div className="shrink-0 w-12 sm:w-48">
                </div>
              </div>

                <div className="shrink-0 snap-center w-[200px] h-[150px] rounded-[8px] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                  <div className="overflow-hidden h-[150px] rounded border-[2px] border-black">
                    <img src={gamification} alt="gamification" />
                  </div>
                  <div className="absolute left-5 right-5 h-[25px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-black">
                      <div className="absolute left-0 right-0 bottom-0 mx-[10px]">
                        <p className="flex justify-center">Gamification</p>
                      </div>
                  </div>
                </div>

                <div className="shrink-0 snap-center w-[200px] h-[150px] rounded-[8px] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                  <div className="overflow-hidden h-[150px] rounded border-[2px] border-black ">
                    <img className="w-[200px] h-[150px]" src={raimont} alt="raimont" />
                  </div>
                  <div className="absolute left-5 right-5 h-[25px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-black">
                      <div className="absolute left-0 right-0 bottom-0 mx-[10px]">
                        <p className="flex justify-center">Raimont</p>
                      </div>
                  </div>
                </div>

                <div className="shrink-0 snap-center w-[200px] h-[150px] rounded-[8px] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                  <div className="overflow-hidden h-[150px] rounded border-[2px] border-black ">
                    <img className="w-[200px] h-[150px]" src={ecommerce} alt="ecommerce" />
                  </div>
                  <div className="absolute left-5 right-5 h-[25px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-black">
                      <div className="absolute left-0 right-0 bottom-0 mx-[10px]">
                        <p className="flex justify-center">Ecomerce</p>
                      </div>
                  </div>
                </div>

                <div className="shrink-0 snap-center w-[200px] h-[150px] rounded-[8px] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                  <div className="overflow-hidden h-[150px] rounded border-[2px] border-black ">
                    <img className="w-[200px] h-[150px]" src={locationFinder} alt="locationFinder" />
                  </div>
                  <div className="absolute left-5 right-5 h-[25px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-black">
                      <div className="absolute left-0 right-0 bottom-0 mx-[10px]">
                        <p className="flex justify-center">LocationFinder</p>
                      </div>
                  </div>
                </div>

                <div className="shrink-0 snap-center w-[200px] h-[150px] rounded-[8px] bg-gradient-to-b from-[#032134] to-[#000000] relative">
                  <div className="overflow-hidden h-[150px] rounded border-[2px] border-black ">
                    <img className="w-[200px] h-[150px]" src={foodtruck} alt="foodtruck" />
                  </div>
                  <div className="absolute left-5 right-5 h-[25px] mx-auto bottom-[-20px] rounded-full border bg-gradient-to-b from-[#032134] to-[#000000] border-black">
                      <div className="absolute left-0 right-0 bottom-0 mx-[10px]">
                        <p className="flex justify-center">Foodtruck</p>
                      </div>
                  </div>
                </div>

                <div className="snap-center shrink-0">
                  <div className="shrink-0 w-12 sm:w-48">
                  </div>
                </div>
              
              </div>
            </div>
        </div>
      </div>
        <div className="hidden md:flex pt-[250px] px-14">
          <div className="projects bg-black bg-opacity-10" >
            <div className="flex flex-col">
              <div className="flex justify-center mt-10 font-bold text-[35px]">
                Projects
              </div>

              <div className="flex justify-center mt-5">
                <div className="w-[150px] rounded-[8px] bg-black bg-opacity-30 flex justify-center">
                  <div className="flex flex-row items-center gap-1">
                    <div
                      className={`tab w-[40px] flex items-center justify-center font-bold cursor-pointer ${
                        styles.tab
                      }
                      ${activeTab === "first" && `${styles.active}`}
                      `}
                      onClick={() => setActiveTab("first")}
                    >
                      1
                    </div>
                    <div
                      className={`tab w-[40px] flex items-center justify-center font-bold cursor-pointer ${
                        styles.tab
                      }
                      ${activeTab === "second" && `${styles.active}`}
                      `}
                      onClick={() => setActiveTab("second")}
                    >
                      2
                    </div>
                    <div
                      className={`w-[40px] flex items-center justify-center font-bold cursor-pointer ${
                        styles.tab
                      }
                      ${activeTab === "third" && `${styles.active}`}
                      `}
                      onClick={() => setActiveTab("third")}
                    >
                      3
                    </div>
                  </div>
                </div>
              </div>
              {activeTab === "first" && (
                
                <div className={`mt-20 flex flex-row text-black items-center justify-center ${ setActiveTab ? "tab-fade-in" : "" } `}>
                  <div
                    className={`flex flex-row gap-[50px] xl:gap-[90px] content ${
                      activeTab === "first" && `${styles.active}`
                    }
                    `}
                  >
                    {firstProjects.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </div>
                </div>
              )}

              {activeTab === "second" && (
                <div className={`mt-20 text-black flex items-center justify-center ${ setActiveTab ? "tab-fade-in" : "" } `}>
                  <div className="flex flex-row gap-[50px] xl:gap-[90px]">
                  {secondProjects.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </div>
                </div>
              )}

              {activeTab === "third" && (
                <div className={`mt-20 text-white flex items-center justify-center ${ setActiveTab ? "tab-fade-in" : "" } `}>
                  more projects coming soon
                </div>
              )}
            </div>
          </div>
        </div>
      
    </div>
    
  );
}
